import {TableBase} from "./table_base";
import {ControlledTableBase} from "./controlled_table_base";

$(() => {
    if(app && app.DASHBOARD_CORE) {
        Object.keys(app.OPTIONS.gridstack_allowed).forEach((gridId) => {
            const opts = app.OPTIONS.gridstack_allowed[gridId];
            if(opts.renderType === 'table_new' && opts.enabled && opts.table_custom === false) {
                new ControlledTableBase(gridId);
                return;
            }

            if(opts.renderType !== 'table' || !opts.enabled || opts.table_custom !== false) {
                return;
            }

            new TableBase(gridId);
        });
    } else {
        console.warn('DashboardCore not initialized.');
    }
});