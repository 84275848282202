import $ from 'jquery';
import {Toast} from "../../shared/toast";
import {AjaxPromise} from "../../shared/ajax_utilities";
import {manifest} from "../../shared/common";

export class ControlledTableBase {
    GRID_ID;
    LOADING = false;
    DATA = [];
    COLUMNS = [];
    SORTING = {};
    SORT_BY = null;
    CURRENT_PAGE = 0;
    MAX_PAGE = 1;
    DOM = {};
    PREVIOUS_FILTERS = {};
    FILTERS = {};
    WIDGET_OPTIONS = {};

    constructor(gridId) {
        this.GRID_ID = gridId;
        app.DASHBOARD_CORE.CONTROLLED_TABLE_CLASSES[gridId] = this;
        this.DOM.parent = $(`#${this.GRID_ID}`);
        this.DOM.table = this.DOM.parent.find(`table`);
        this.DOM.thead = this.DOM.table.find('thead');
        this.DOM.bodyLoading = this.DOM.table.find('.loading-body');
        this.DOM.body = this.DOM.table.find('.main-body');
        this.WIDGET_OPTIONS = app.OPTIONS.gridstack_allowed[gridId];
        app.DASHBOARD_CORE.PARENT_EL.dispatchEvent(new CustomEvent('RegisterWidget', {
            detail: {
                gridId: gridId,
                instance: this,
            }
        }));
    }

    async setup() {
        this.setLoading(true, true);
        await this.getData();
        this.paintTable();
        this.setupEvents();
        this.setLoading(false, true);
    }

    setupEvents() {
        // Allow easily adding new filter types via custom setup methods
        if(this.WIDGET_OPTIONS.filters) {
            Object.keys(this.WIDGET_OPTIONS.filters).forEach((key) => {
                const filter = this.WIDGET_OPTIONS.filters[key];
                if (this[`${filter.type}FilterSetup`]) {
                    this[`${filter.type}FilterSetup`]();
                }
            });
        }

        this.DOM.body.find('[data-href]').unbind('click').on('click', async (e) => {
            const el = $(e.currentTarget);
            try {
                const res = await AjaxPromise({ url: el.data('href') });
                if(res.status === 'success') {
                    el.parent().parent().addClass('fadeOut').fadeOut('slow');
                }
            } catch {}
        });

        this.DOM.thead.find('.sortable').unbind('click').on('click', async (e) => {
            const el = $(e.currentTarget);
            const currentSorting = el.data('sort');
            let changed = false;
            if(!currentSorting) {
                // Set to ASC sort
                el.data('sort', 'ASC').attr('data-sort', 'ASC');
                el.find('i').addClass('fa fa-circle-arrow-up');
                this.SORTING[el.attr('id')] = 'ASC';
                changed = true;
            } else if (currentSorting === 'ASC') {
                // Set to DESC sort
                el.data('sort', 'DESC').attr('data-sort', 'DESC');
                el.find('i').addClass('fa fa-circle-arrow-down');
                this.SORTING[el.attr('id')] = 'DESC';
                changed = true;
            } else if(currentSorting === 'DESC') {
                // Remove Sort
                el.data('sort', '').removeAttr('data-sort');
                el.find('i').removeClass('fa fa-circle-arrow-up fa-circle-arrow-down');
                delete this.SORTING[el.attr('id')];
                changed = true;
            }

            if(!changed) {
                return;
            }
            this.CURRENT_PAGE = 0;
            const allFilters = this.DOM.parent.find('.buttons input, .buttons select');
            allFilters.attr('disabled', 'true');
            if(this.LOADING) {
                return;
            }
            this.setLoading(true);
            await this.getData();
            this.setLoading(false);
            allFilters.removeAttr('disabled');
        });

        this.DOM.parent.find('.scrollable').unbind('scroll').on('scroll', async (e) => {
            if(this.LOADING || this.CURRENT_PAGE === this.MAX_PAGE || this.MAX_PAGE === -1) {
                return;
            }
            const el = $(e.currentTarget);
            if(Math.ceil(el.scrollTop() + el.innerHeight()) >= el[0].scrollHeight) {
                ++this.CURRENT_PAGE;
                const allFilters = this.DOM.parent.find('.buttons input, .buttons select');
                allFilters.attr('disabled', 'true');
                this.setLoading(true);
                await this.getData();
                this.setLoading(false);
                allFilters.removeAttr('disabled');
            }
        });
    }

    autocompleteFilterSetup() {
        this.DOM.parent.get(0).addEventListener('AutoCompleteSelect', async (e) => {
            const val = e.detail.data.value;
            await this.updateFilter(e.detail.filterId, val);
        });

        this.DOM.parent.get(0).addEventListener('AutoCompleteClear', async (e) => {
            await this.updateFilter(e.detail.filterId);
        });
    }

    selectFilterSetup() {
        this.DOM.parent.find('.filters select').unbind('change').on('change', async (e) => {
            const el = $(e.currentTarget);
            const filterId = el.data('filterId');
            if(!filterId || filterId.length === 0) {
                return;
            }

            await this.updateFilter(filterId, el.val());
        });
    }

    async updateFilter(filterId, val) {
        const allFilters = this.DOM.parent.find('.buttons input, .buttons select');
        if(val && val.trim().length !== 0) {
            this.FILTERS[filterId] = val;
        } else {
            delete this.FILTERS[filterId];
        }
        this.CURRENT_PAGE = 0;
        this.MAX_PAGE = 1;

        allFilters.attr('disabled', 'true');
        if(this.LOADING) {
            return;
        }

        this.setLoading(true);
        await this.getData();
        this.DOM.parent.find('.scrollable').scrollTop(0);
        this.setLoading(false);
        allFilters.removeAttr('disabled');
    }

    async getData() {
        try {
            const res = await AjaxPromise({
                'url': `${app.CACHE.URL_AJAX}dashboard_table`,
                'method': 'POST',
                'data': {
                    gs_id: this.GRID_ID,
                    filters: this.FILTERS,
                    sorting: this.SORTING,
                    sort_by: this.SORT_BY,
                    page: this.CURRENT_PAGE,
                },
            });

            if(res.status !== 'success') {
                return;
            }

            this.MAX_PAGE = res.max_pages;
            if(res.columns) {
                this.COLUMNS = res.columns;
            }

            if(this.CURRENT_PAGE === 0) {
                this.DATA = res.data;
            } else {
                this.DATA.push(...res.data);
            }

            if(res.row_type === 'tpl') {
                this.paintTableTPL(res.data);
            } else {
                this.paintTable(res.data);
            }
            this.PREVIOUS_FILTERS = this.FILTERS;
            this.setupEvents();
        } catch(err) {
            console.log(err);
        }
    }

    paintTable(data) {
        if(this.DOM.thead.find('tr').length === 0) {
            let columns = `<tr>`;
            this.COLUMNS.forEach((col) => {
                if (col.sortable) {
                    columns += `<th id="${col.key}" class="sortable">${col.name}<i></i></th>`;
                    return;
                }
                columns += `<th id="${col.key}">${col.name}</th>`;
            });
            columns += `</tr>`;
            this.DOM.thead.html(columns);
        }

        data = data ?? this.DATA;
        if(this.CURRENT_PAGE === 0) {
            // Create a fresh table as this is page 1
            this.DOM.body.html('');
            if(data.length === 0) {
                const cols = this.COLUMNS.length === 0 ? 50 : this.COLUMNS.length;
                this.DOM.body.append(`<tr><td colspan="${cols}">No data matching the current filters.</td></tr>`);
                app.DASHBOARD_CORE.populateAdditionalRow(this.DOM.parent);
                return;
            }
        }

        data.forEach((row) => {
            const tr = document.createElement('tr');
            if(row.id) {
                tr.id = row.id;
            }

            if(row.tr_classes) {
                tr.className = row.tr_classes;
            }

            this.COLUMNS.forEach((col) => {
                const td = document.createElement('td');
                if(row[col.key]) {
                    td.innerHTML = row[col.key];
                }

                tr.append(td);
            });

            this.DOM.body.append(tr);
        });

        app.DASHBOARD_CORE.populateAdditionalRow(this.DOM.parent);
    }

    paintTableTPL(data) {
        data = (data ?? this.DATA);
        if(this.CURRENT_PAGE === 0) {
            // Create a fresh table as this is page 1
            this.DOM.body.html('');
            if(data.length === 0) {
                const cols = this.COLUMNS.length === 0 ? 50 : this.COLUMNS.length;
                this.DOM.body.append(`<tr><td colspan="${cols}">No data matching the current filters.</td></tr>`);
                app.DASHBOARD_CORE.populateAdditionalRow(this.DOM.parent);
                return;
            }
        }

        data.forEach((row) => {
            this.DOM.body.append(row);
        });

        app.DASHBOARD_CORE.populateAdditionalRow(this.DOM.parent);
    }

    setLoading(loading, fullLoad = false) {
        this.LOADING = loading;
        const cols = this.COLUMNS.length === 0 ? 50 : this.COLUMNS.length;
        if(fullLoad && loading) {
            this.DOM.body.hide().html(`<td colspan="${cols}"></td>`);
            this.DOM.table.css('height', '100%');
            this.DOM.bodyLoading.show();
            this.LOADING = true;
        } else if(!fullLoad && loading) {
            this.DOM.body.append(`<tr class="loader"><td colspan="${cols}"><img src="${manifest('img/loading-black.svg')}" width="30"></td></tr>`);
            this.DOM.bodyLoading.hide();
            this.LOADING = true;
        } else {
            this.DOM.body.find('.loading').remove();
            this.DOM.body.find('.loader').remove();
            this.DOM.body.show();
            this.DOM.table.css('height', 'auto');
            this.DOM.parent.find('.scrollable').addClass('allow-overflow');
            this.DOM.bodyLoading.hide();
            this.LOADING = false;
        }
    }
}
